import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getCarouselImages } from '../../../redux/actions/landingActions';
import { getSliders } from '../../../request';
import cocaCarga from '../../../assets/images/carga.png';


const useStyles = makeStyles((theme) => ({
  buttons: {
    margin: theme.spacing(2),
    height: '70px',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: 50,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#c3c3c3',
    }
  },
  container: {
    marginTop: -55,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#da1325',
    height: '100vh',
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  splashImage: {
    width: '50%',
    minWidth: 150,
    maxWidth: 200,
    height: '100%',
    maxHeight: 650
  }
}));

export default function ({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { landingReducer } = useSelector(state => state);
  const { images } = landingReducer;
  
  const [animation, setAnimation] = useState('animate__animated animate__zoomIn animate__delay-1s')


  useEffect(() => {
    let exec = true;
    if (exec) {
      if (!images?.length) { getCarouselImagesRequest() }  
    }
    return () => { exec = false }
  }, []);

  const getCarouselImagesRequest = async () => {
    await getSliders().then(res => dispatch(getCarouselImages(res)))
  }


  useEffect(() => {
    let exec = true;
    if (exec) {
      setTimeout(() => { setAnimation('animate__animated animate__zoomOut') }, 3000);
      setTimeout(() => { history.push('/consumidores/home') }, 3500);
    }
    return () => { exec = false }
  }, []);


  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={animation}>
          <img src={cocaCarga} className={classes.splashImage} />
        </div>
      </div>
    </div>
  );
}

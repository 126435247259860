import React from 'react';
import Navbar from '../../commons/navBar';
import { useStyles } from './styleQr';
import { Button } from '@material-ui/core';
import { paleta } from '../../../assets/colors';
import './index.css'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


export const QrOk = (props) => {
    const classes = useStyles();
    const { res, history } = props;

    return (
        <div className={classes.containerMain}>
            <Navbar
                link="/inicio"
                logoRojo={false}
                colorFondo={paleta.rojoCoca}
                history={history} />


            <div className={classes.okScreen}>

                <div style={{
                    textAlign: 'center', marginBottom: '10%',
                    color: '#da1325'
                }}>
                    <h1>¡EXCELENTE!</h1>
                </div>
                <div
                    style={{
                        borderRadius: '80px 0 80px 80px',
                        backgroundColor: '#da1325',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        padding: '0 10%',
                        fontSize: '8vw',
                        color: 'white',
                    }}>
                    <p>YA PODÉS<br />DISFRUTAR DE<br />TU PRODUCTO</p>
                </div>

            </div>
            {/* <Button variant="contained"
                className={classes.button}
                onClick={() => history.push('/app/mis-cupones-canjeados')}>Ir a mis canjes</Button> */}

        </div>
    )
}
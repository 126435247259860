export default {
  API_URL: 'https://femsa.trazzart.com/api',
  API_VERSION: '/v1',
  firebaseConfig: {
    apiKey: "AIzaSyAmjC9Dsh-J7fzc1yZfDyEZfr5xOHNPZaU",
    authDomain: "femsa-arg-consumidores.firebaseapp.com",
    projectId: "femsa-arg-consumidores",
    storageBucket: "femsa-arg-consumidores.appspot.com",
    messagingSenderId: "919901990172",
    appId: "1:919901990172:web:43e4e89693437f78861ed9",
    measurementId: "G-SQPWP4QESH"
  },
  
};

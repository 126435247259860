import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';

// importando icons and colors
import Navbar from '../../commons/navBar';

import couponIcon from '../../../assets/icons/_ICONO CUPONES.png';
import CentrosIcon from '../../../assets/icons/_ICONO CENTRO DE CANJE.png';
import favIcon from '../../../assets/icons/_ICONO MIS CUPONES.png';


import Carousel from '../../commons/carrousel';
import colors, { paleta } from '../../../assets/colors';



const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#da1325',
    height: '100vh',
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  buttons: {
    fontSize: '2.5vh',
    fontWeight: 'bold',
    margin: theme.spacing(0.1),
    height: '14vh',
    color: colors.palette.white,
    justifyContent: 'unset',
    border: 'unset',
    '&:hover': {
      border: 'unset !important'
    },
    padding: 20,
    paddingLeft: 0
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  divContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // borderTopColor: '#00000099',
    // borderTopWidth: '3px',
    // borderTopStyle: 'solid',
    backgroundColor: colors.palette.primary,

  },

  containerButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    backgroundColor: colors.palette.primary,
    paddingLeft: '3%',
    paddingRight: '3%',
    width: '100%',
    maxWidth: 450,
    overflow: 'hidden',
    borderTopColor: '#9b0303',
    borderTopWidth: '7px',
    borderTopStyle: 'solid',
  },
  imgIcons: {
    paddingRight: '20px',
    width: 80,
  },
  imgStyles: {
    height: '100%',
    objectFit: 'cover'
  },
  carousel: {
    display: 'flex',
    backgroundColor: colors.palette.primary,
  },
  lineContainer: {
    height: 1,
    width: '100%',
    backgroundColor: 'white',
  },

}));




export default function ({ history }) {
  const classes = useStyles();

  const { loginReducer } = useSelector(state => state);
  const { userLoggedIn } = loginReducer;


  const lineBreak = () => (
    <div className={classes.lineContainer}></div>
  )


  useEffect(() => {
  let excecute = true;
  if (excecute) {
    console.log(userLoggedIn?.token)
  }
  return () => (excecute = false);
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.content}>

        <Navbar
          link="/inicio"
          logoRojo={false}
          colorFondo={paleta.rojoCoca}
          history={history} />

        <Carousel />

        <div className={classes.containerButtons}>

          <Button
            component={Link}
            to={userLoggedIn?.token ? '/app/mis-cupones-disponibles' : '/consumidores/cupones'}
            variant="outlined"
            size="large"
            color="primary"
            className={classes.buttons}
          >


            <img className={classes.imgIcons} src={couponIcon} alt="icon-cupon" />
            PROMOCIONES
          </Button>
          {lineBreak()}

          <Button
            component={Link}
            to={'/consumidores/puntos-venta'}
            variant="outlined"
            size="large"
            color="primary"
            className={classes.buttons}
          >
            <img className={classes.imgIcons} src={CentrosIcon} alt="icon-centros-canje" />
            CENTROS DE CANJE
          </Button>
          {lineBreak()}

          <Button
            component={Link}
            to={userLoggedIn?.token ?
              '/app/mis-cupones-canjeados' : '/account/login'}
            variant="outlined"
            size="large"
            color="primary"
            className={classes.buttons}
          >
            <img className={classes.imgIcons} src={favIcon} alt="icon-mis-cupones" />
            MIS CUPONES
          </Button>
          {lineBreak()}

        </div>

      </div>

    </div>

  );
}

import { typesApp } from "../types";



const landingReducer = (state = { images: [] }, action) => {
        
    switch (action.type) {
        case typesApp.SET_CAROUSEL_IMAGES:
            return {
                ...state,
                images: action.payload.arrImages
            }
        default:
            return state;
    }
}

export default landingReducer;
import { typesApp } from "../types";


const INITIAL_STATE = { 
  coupon_selected: {}
};

const couponsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case typesApp.SET_COUPON_SELECTED:
      return {
        ...state,
        coupon_selected: action.payload.couponSelected
      }



    default:
      return state;
  }
};

export default couponsReducer;

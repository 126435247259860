import { makeStyles } from '@material-ui/core';
import colors from '../../../assets/colors';

export const useStyles = makeStyles(() => ({
  btnScan: {
    margin: '20%',
    padding: '20% 10%',
    backgroundColor: 'black',
    borderRadius: 10,
    color: 'white',
    fontSize: 'x-large'
  },
  containerMain: {
    flex: 1,
    width: '100%',
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  camera: {
    width: '100%',
    height: '6vh',
    display: 'flex',
    marginTop: '5%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textCamera: {
    fontWeight: 'bold',
    fontSize: '4.5vw',
    margin: '5% 1%',
  },
  divFather: {
    height: '75vh',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 600
  },
  inputQR: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 25px',
  },
  containerBackground: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  qr: {
    width: '100%',
    maxWidth: 450,
    alignSelf: 'center',
    padding: 25,
    zIndex: 999
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    width: '100%',
    maxWidth: 450,
    marginTop: 20
  },
  title: {
    width: '90%',
    alignSelf: 'center',
  },
  input: {
    width: '100%',
    marginRight: '1%',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    textAlign: 'center',
    padding: '5%',
    outline: 'none',
    fontWeight: 'bold',
    // textTransform: 'lowercase',
    border: `2px solid ${colors.palette.primary}`,
    '&::placeholder': {
      color: colors.palette.secondary,
      textAlign: 'center'
    }
  },
  footer: {
    textAlign: 'center'
  },
  textFooter: {
    margin: '5% 1%',
    fontSize: '4.5vw',
    backgroundColor: 'lightgray',
    fontStyle: 'italic',
    padding: '5px',
  },
  btnOK: {
    backgroundColor: '#000000d4',
    color: 'white',
    borderRadius: '0 5px 5px 0',
    borderStyle: 'none',
    padding: '10px',
  },
  okScreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
    width: '80%',
    alignSelf: 'center',
  },
  button: {
    WebkitBorderRadius: '5px',
    borderRadius: '5px',
    backgroundColor: '#231f20ff',
    color: '#ffffffff',
    border: 'none',
    alignSelf: 'center',
    padding: '10px 5px',
    width: '100%',
    letterSpacing: 3
  },
  containerLoading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },


}));
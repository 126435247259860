import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Switch, Redirect } from 'react-router-dom';

import QrScanner from '../layout/ui/qrScanner';
import Canjeado from '../layout/ui/canjeado';
import Home from '../layout/ui/home';
import SwapCenters from '../layout/ui/swapCenters';
import SingleCoupon from '../layout/ui/singleView';

import inicio from '../layout/ui/inicio';
import MyCoupons from '../layout/ui/myCoupons';
import MyBurnCoupons from '../layout/ui/myBurnCoupons';
import { QrOk } from '../layout/ui/qrScanner/QrOk';
import { QrError } from '../layout/ui/qrScanner/QrError';
import { NewSwapCenter } from '../layout/ui/swapCenters/NewSwapCenter';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
  },
  root: {
    flex: 1,
    marginTop: 55,
    display: 'flex',
    backgroundColor: 'black',
  },

  toolbar: theme.mixins.toolbar,
}));

const PrivateRoutes = function () {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />

      <main className={classes.content}>
        <Switch>
          <Route exact path="/consumidores" component={inicio} />
          <Route exact path="/consumidores/home" component={Home} />
          <Route exact path="/consumidores/puntos-venta" component={SwapCenters} />
          <Route exact path="/consumidores/puntos-venta/add" component={NewSwapCenter} />

          {/* Cupones del usuario logueado */}
          <Route exact path="/app/mis-cupones-disponibles" component={MyCoupons} />
          <Route exact path="/app/tienda-qr" component={QrScanner} />
          <Route exact path="/app/cupon" component={SingleCoupon} />
          <Route exact path="/app/cupon-ok" component={QrOk} />
          <Route exact path="/app/cupon-error" component={QrError} />

          {/* Cupones Canjeados del usuario logueado */}
          <Route exact path="/app/mis-cupones-canjeados" component={MyBurnCoupons} />

          {/* Canjeado con éxito */}
          <Route exact path="/app/canje-exitoso" component={Canjeado} />

          <Route exact path="*">
            <Redirect to="/consumidores/home" />
          </Route>

        </Switch>
      </main>
    </div>
  );
};

export default PrivateRoutes;

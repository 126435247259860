import { typesApp } from "../types";


const INITIAL_STATE = {
    position: null,
    points: [],
}

const pointOfSaleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case typesApp.SET_CURRENT_POSITION:
            return {
                ...state,
                position: action.payload.currentPosition
            }

        case typesApp.SET_POINTS_OF_SALE:
            return {
                ...state,
                points: action.payload.pointsLocated
            }

        default:
            return state;
    }
};

export default pointOfSaleReducer;

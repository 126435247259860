import React from 'react';
import { IconButton, Toolbar } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logo_coca_cola from '../../../assets/icons/_LOGO HEADER.png';
import logo_coca_cola_rojo from '../../../assets/icons/_LOGO HEADER ROJO.png';
import Drawer from '../drawer';

import { useStyles } from './styles';
import { paleta } from '../../../assets/colors';




export default function ButtonAppBar({
  link,
  history,
  colorFondo,
  logoRojo = true }) {
  const classes = useStyles();


  return (

    <div className={classes.appBar}>
      <Toolbar
        style={{
          backgroundColor: colorFondo,
          paddingLeft: 5,
          paddingRight: 0,
        }}>

        <IconButton
          edge="start"
          className={classes.menuButtonLeft}
          aria-label="menu"
          onClick={() => {
            if (link?.length) {
              history.push("./home")

            } else {
              history.goBack()
            }

          }
          }
        >
          {
            history && history.location.pathname === '/consumidores/home'
              ? null
              : <ArrowBackIcon style={{
                color: logoRojo ? paleta.rojoCoca : paleta.blanco
              }} />
          }
        </IconButton>


        <div onClick={() => history.push('/consumidores/home')} className={classes.title}>
          <img className={classes.imgNavbar}
            src={logoRojo ? logo_coca_cola_rojo : logo_coca_cola} alt="img-coca-cola" />
        </div>

        <div className={classes.menuButtonRight}>
          <Drawer
            colorCustom={logoRojo ? paleta.rojoCoca : paleta.blanco}
            history={history} />
        </div>


      </Toolbar>
    </div>


  );
}



export const typesApp = {
    SET_CAROUSEL_IMAGES: 'SET_CAROUSEL_IMAGES',

    REGISTER_USER: 'REGISTER_USER',
    lOGIN_USER: 'lOGIN_USER',
    LOGOUT: 'LOGOUT',

    SET_COUPON_SELECTED: 'SET_COUPON_SELECTED',
    SET_QR_BURN: 'SET_QR_BURN',

    SET_CURRENT_POSITION: 'SET_CURRENT_POSITION',
    SET_POINTS_OF_SALE: 'SET_POINTS_OF_SALE',
}
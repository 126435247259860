import { makeStyles } from '@material-ui/core/styles';



export const useStyles = makeStyles((theme) => ({

    appBar: {
        top: 0,
        zIndex: 99,
        boxShadow: 'none',
        position: 'fixed',
        width: '100%',
        height: '10vh'
    },

    menuButtonLeft: {
        flex: 1,
        justifyContent: 'flex-start',
        marginLeft: '1px'

    },
    menuButtonRight: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
    title: {
        flex: 8,
        display: 'flex',
        justifyContent: 'center',
    },
    imgNavbar: {
        height: '40px',
        width: '100px',
        paddingBottom: '5px',
    },

}));
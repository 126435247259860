import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCouponsBurnByUser } from '../../../request';
import GridCoupons from '../../commons/gridCoupons';
import Navbar from '../../commons/navBar';

import iconNav from '../../../assets/icons/_ICONO MIS CUPONES.png'
import { CircularProgress } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { useStyles } from '../qrScanner/styleQr';
import { Button } from '@material-ui/core';
import { NavBarTitleCustom } from '../../commons/navBar/NavBarTitleCustom';



const MyBurnCoupons = ({ history }) => {
  const classes = useStyles()
  const { loginReducer } = useSelector(state => state);
  const { token } = loginReducer.userLoggedIn;

  const [myCouponsResp, setMyCouponsResp] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let exec = true;
    if (exec) {
      if (token) { getMyCoupons() }
    }
    return () => { exec = false }
  }, []);


  const getMyCoupons = async () => {
    await getCouponsBurnByUser(token)
      .then(res => {
        setMyCouponsResp(res)
        setLoading(false)
      })
  }


  const emptyListCoupons = () => (


    <div className={classes.okScreen}>
      <div>
        <ErrorOutlineOutlinedIcon style={{ fontSize: '20vh', color: '#115293' }} />
      </div>
      <div style={{
        textAlign: 'center',
        marginBottom: '10%'
      }}>
        <h2>YA ES HORA<br />CANJEA CUPONES</h2>
        <p>Acercate a los centros de canje habilitados más cercanos y aprovechá todas las promos y descuentos que tenemos para vos.</p>
      </div>
      <Button variant="contained"
        className={classes.button}
        onClick={() => history.push('./home')}>Volver</Button>

    </div>


  )


  return (
    <>
      <Navbar
        link="./home"
        logoRojo={true}
        colorFondo={'white'}
        history={history} />

      {myCouponsResp.length ?
        <NavBarTitleCustom titleCustom={"MIS CUPONES"} iconNav={iconNav} /> :
        null}


      {
        loading ?
          <div className={classes.containerLoading}><CircularProgress disableShrink /></div> :
          (myCouponsResp.length ?
            <GridCoupons
              burn={true}
              titlePrincipal="MIS CUPONES"
              coupons={myCouponsResp}
              history={history}
            /> :
            emptyListCoupons())
      }
    </>
  );
};

export default MyBurnCoupons;

import React, { useState, useEffect } from 'react';
import { useForm } from '../../../hooks/useForm';
import { createNewShop } from '../../../request';

export const NewSwapCenter = () => {
    const [formValues, handleInputChange, reset] = useForm({});
    const {
        cuit,
        shop_name,
        owner,
        blive_client,
        femsa_client,
        address,
        city,
        state,
        zip,
        email,
        phone,
        qr_code,
        lat,
        lng } = formValues;

    const objShop = {
        cuit,
        shop_name,
        owner,
        blive_client,
        femsa_client,
        address,
        city,
        state,
        zip,
        email,
        phone,
        qr_code,
        lat,
        lng,
    }

    const createShop = () => {
        createNewShop(objShop, 'JOSEBOZZONE ');
    }
    return (
        <div>
            <div>
                <input placeholder="cuit" name='cuit' value={cuit} onChange={handleInputChange} />
                <input placeholder="shop_name" name='shop_name' value={shop_name} onChange={handleInputChange} />
                <input placeholder="owner" name='owner' value={owner} onChange={handleInputChange} />
                <input placeholder="blive_client" name='blive_client' value={blive_client} onChange={handleInputChange} />
                <input placeholder="femsa_client" name='femsa_client' value={femsa_client} onChange={handleInputChange} />
                <input placeholder="address" name='address' value={address} onChange={handleInputChange} />
                <input placeholder="city" name='city' value={city} onChange={handleInputChange} />
                <input placeholder="state" name='state' value={state} onChange={handleInputChange} />
                <input placeholder="zip" name='zip' value={zip} onChange={handleInputChange} />
                <input placeholder="email" name='email' value={email} onChange={handleInputChange} />
                <input placeholder="phone" name='phone' value={phone} onChange={handleInputChange} />
                <input placeholder="qr_code" name='qr_code' value={qr_code} onChange={handleInputChange} />
                <input placeholder="lat" name='lat' value={lat} onChange={handleInputChange} />
                <input placeholder="lng" name='lng' value={lng} onChange={handleInputChange} />


                <button onClick={createShop} >CREAR SHOP
                </button>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import NavbarTitle from '../navBarIcon';

const useStyles = makeStyles(() => ({
    bannerTitle: {
        backgroundColor: '#da1325',
        height: '13vh',
        display: 'flex',
        borderRadius: '0 0 50px 50px',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        top: '-2px',
    },
}))


export const NavBarTitleCustom = ({ titleCustom, iconNav }) => {
    const classes = useStyles();


    return (
        <div className={classes.bannerTitle}>
            <NavbarTitle
                img={iconNav}
                title={titleCustom}
            />
        </div>
    )
}
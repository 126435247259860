import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import {
  GoogleMap,
  Marker,
  InfoWindow,
  LoadScript,
} from '@react-google-maps/api';

import { makeStyles } from '@material-ui/core/styles';
import { getShops, getShopsByZip } from '../../../request';

import me from './me.png';
import shop from './shop.png';


import Navbar from '../../commons/navBar';
import NavBarTitle from '../../commons/navBarIcon';
import canje from '../../../assets/icons/_ICONO CENTRO DE CANJE.png';

import style, { paleta } from '../../../assets/colors';
import { useForm } from '../../../hooks/useForm';
import mapStyles from './mapStyles'
import colors from '../../../assets/colors';

import GpsFixedIcon from '@material-ui/icons/GpsFixed';


const useStyles = makeStyles(() => ({
  bannerTitle: {
    backgroundColor: '#da1325',
    height: '13vh',
    display: 'flex',
    borderRadius: '0 0 50px 50px',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '-2px',
    zIndex: 9
  },
  titleOfLocation: {
    color: '#080808',
    fontSize: 20,
    margin: 0
  },
  containerGrid: {
    display: 'flex',
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 16,
    flex: 1,
    flexDirection: 'column'
  },

  formControl: {
    minWidth: 340,
    minHeight: '50px',
    top: 0,
    backgroundColor: style.palette.white,
    display: 'flex',
    paddingLeft: 24,
    paddingRight: 24,
    margin: '0 auto',
    width: '95%'
  },

  container: {
    display: 'flex',
    flex: 1,
    backgroundColor: style.palette.primary,
    justifyContent: 'center',
  },
  divFather: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: style.palette.primary,
    paddingTop: 16,
    maxWidth: 450,
    marginTop: 85
  },
  containerLocation: {
    display: 'flex',
    marginTop: 16,
    alignItems: 'center'
  },
  divContainerInfoLocaction: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: 10
  },
  pStreetLocation: {
    fontSize: 16,
    color: '#616060',
    margin: 0
  },
  containerLoading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerBackgroundEmpty: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 16,
  },
  button: {
    cursor: 'pointer',
    WebkitBorderRadius: '50px',
    borderRadius: '50px',
    backgroundColor: '#231f20ff',
    color: '#ffffffff',
    border: 'none',
    alignSelf: 'center',
    flex: 1,
    padding: 8,
    paddingRight: 16,
    paddingLeft: 16,
    marginTop: 8
  },
  buttonContainer: {
    backgroundColor: style.palette.white,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  altText: {
    textAlign: 'center',
    marign: 0,
  },
  distanceText: {
    color: '#AAAAAA',
    float: 'right',
    fontWeight: 100,
    fontSize: 14,
  },
  areaZipCode: {
    width: '100vw',

    height: '15vh',

    position: 'absolute',
    bottom: '0',
    zIndex: 9,
    padding: '0 10vw 10vh 10vw'
  },
  inputZip: {
    flex: 1,
    display: 'flex',
    width: '100%',
    maxWidth: 450,
    marginTop: 5
  },
  inputTextZip: {
    width: '100%',
    marginRight: '1%',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    textAlign: 'center',
    padding: '3%',
    outline: 'none',
    fontWeight: 'bold',
    textTransform: 'lowercase',
    border: `2px solid ${colors.palette.primary}`,
    '&::placeholder': {
      color: colors.palette.secondary,
      textAlign: 'center'
    }
  },
  btnBuscar: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '0 50px 50px 0',
    padding: '0 10px'
  }

}));

/* Gmaps */
const libraries = ["places"]
const mapContainerStyle = {
  position: 'absolute',
  top: 0,
  zIndex: 0,
  width: '100vw',
  height: '100vh',
}


const options = {
  styles: mapStyles,
  disableDefaultUI: true,
}


export default function ({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [formValues, handleInputChange, reset] = useForm({});
  const { inputCode } = formValues;

  const [geolocationDeny, setGeolocationDeny] = useState(false)

  const [center, setCenter] = useState({ lat: -34.60347516552737, lng: -58.38160163461141 });
  const [current, setCurrent] = useState();
  const [newPositions, seNewPositions] = useState();


  const [pointsOfSalesLocated, setPointsOfSalesLocated] = useState([]);
  const [selected, setSelected] = useState(null)

  const { loginReducer, pointOfSaleReducer } = useSelector(state => state);
  const { token } = loginReducer.userLoggedIn;

  const map = useRef()


  // pide localizacion si no va por defecto, y evalua si esta denegada
  useEffect(() => {
    let exec = true;
    if (exec) {
      locateUser()
      return () => exec = false;
    }
  }, []);

  useMemo(() => {

    console.log("usememo ", newPositions);


  }, [newPositions]);


  useEffect(() => {
    let exec = true;
    if (exec) {
      if (current && pointsOfSalesLocated.length === 0) {
        //("busco puntos porque no encuentro");
        searchShopsByLocation()
      }
    }
    return () => { exec = false }
  }, [current]);

  const locateUser = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        console.log(coords);
        setCurrent({ lat: coords.latitude, lng: coords.longitude })
        setCenter({ lat: coords.latitude, lng: coords.longitude })
      })
    } else {
      setGeolocationDeny(true)
    }
  }

  const searchByCode = async () => {
    await getShopsByZip(inputCode, token)
      .then(res => {

        if (res.length) {
          setPointsOfSalesLocated(res)
          let el = res[0]
          let latitud;
          let longitud;

          if (el.long_lat.length) {

            let lngOri = el.long_lat.indexOf("(") + 1;
            let lngEnd = el.long_lat.indexOf(" ");
            let latOri = lngEnd + 1;
            let latEnd = el.long_lat.indexOf(")");

            latitud = parseFloat(el.long_lat.slice(latOri, latEnd))
            longitud = parseFloat(el.long_lat.slice(lngOri, lngEnd))

            setCenter({ lat: latitud, lng: longitud })

          } else if (el.geo_location.length) {
            longitud = parseFloat(el.geo_location.x);
            latitud = parseFloat(el.geo_location.y);
            setCenter({ lat: latitud, lng: longitud })
          }
        } else {
          setPointsOfSalesLocated([])
        }
      })
      .catch(err => console.log(err))
  }

  const searchShopsByLocation = async () => {
    await getShops(current?.lat, current?.lng, token)
      .then(res => {
        console.log(res);
        setPointsOfSalesLocated(res)
      })
  }

  const onChangeMove = async () => {
    let latitud;
    let longitud;
    if (map?.current?.state?.map?.mapUrl) {

      let url = map?.current?.state?.map?.mapUrl;

      let latOri = url.indexOf("=") + 1;
      let latEnd = url.indexOf(",");

      let lngOri = latEnd + 1;
      let lngEnd = url.indexOf("&");

      longitud = parseFloat(url.slice(lngOri, lngEnd))
      latitud = parseFloat(url.slice(latOri, latEnd))


      await getShops(latitud, longitud, token)
        .then(res => {
          setPointsOfSalesLocated(res)
        })
    }
  }

  return (
    <>
      <Navbar
        link="./home"
        logoRojo={false}
        colorFondo={paleta.rojoCoca}
        history={history} />

      <div className={classes.bannerTitle}>
        <NavBarTitle
          img={canje}
          title="CENTROS DE CANJE"
        />
      </div>


      <LoadScript googleMapsApiKey="AIzaSyCh3kyJLMx6j240mmBT_9ug9UZyAac39e4">

        <GoogleMap
          ref={map}
          zoom={13}
          onDragEnd={onChangeMove}
          center={center ? center : current}
          mapContainerStyle={mapContainerStyle}
          options={options}>

          <Marker visible icon={{ url: me }} position={geolocationDeny ? center : current} />

          {
            pointsOfSalesLocated.map((el, index) => {

              let latitud;
              let longitud;

              if (el.long_lat.length) {
                let lngOri = el.long_lat.indexOf("(") + 1;
                let lngEnd = el.long_lat.indexOf(" ");
                let latOri = lngEnd + 1;
                let latEnd = el.long_lat.indexOf(")");

                latitud = parseFloat(el.long_lat.slice(latOri, latEnd))
                longitud = parseFloat(el.long_lat.slice(lngOri, lngEnd))

              } else if (el.geo_location.length) {
                latitud = parseFloat(el.geo_location.y);
                longitud = parseFloat(el.geo_location.x);
              }

              return (
                <Marker
                  key={index}
                  visible
                  icon={{ url: shop }}
                  position={{ lat: latitud, lng: longitud }}
                  onClick={() => setSelected({
                    address: el.address,
                    city: el.city,
                    shop_name: el.shop_name,
                    state: el.state,
                    zip: el.zip,
                    lat: latitud,
                    lng: longitud,
                  })} />
              )
            })
          }

          {
            selected ?
              (
                <InfoWindow
                  position={{ lat: selected.lat, lng: selected.lng }}
                  onCloseClick={() => setSelected(null)}
                >
                  <div>
                    <h2>{selected.shop_name}</h2>
                    <p>{selected.address}, {selected.city}</p>
                  </div>
                </InfoWindow>
              ) : null
          }




        </GoogleMap>
      </LoadScript>

      <div className={classes.areaZipCode}>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <GpsFixedIcon
            onClick={locateUser}
            style={{
              zIndex: 999,
              fontSize: '10vw',
              color: '#939598'
            }} />
        </div>
        <div className={classes.inputZip}>
          <input
            placeholder="Buscar por Código Postal"
            className={classes.inputTextZip}
            name='inputCode'
            value={inputCode}
            onChange={handleInputChange}
          />
          <button
            className={classes.btnBuscar}
            onClick={searchByCode}
          >Buscar
          </button>
        </div>
      </div>

    </>

  );
}

export default {
  palette: {
    primary: '#da1325',
    secondary: '#939598ff',
    white: '#ffffff',
  }
};


export const paleta = {
  rojoPrincipal: '#da1325',
  blanco: '#ffffff',
  rojoCoca: '#da1325',
  bordo: '#8f3939',
};
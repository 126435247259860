import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardMedia, CircularProgress, makeStyles } from '@material-ui/core';
import { cleanSingle, setCouponSelected } from '../../../redux/actions/couponsActions';
import { useDispatch, useSelector } from 'react-redux';
import single_coupon from '../../../assets/icons/single_cupon.png';


const useStyles = makeStyles({
  root: {
    flex: 1,
    maxHeight: 240,
    maxWidth: 1200,
    paddingRight: 18,
    paddingLeft: 18,
    // borderStyle: 'solid',
    // borderWidth: 1
  },
  media: {
    height: 120,
    paddingTop: '60%',
    backgroundSize: 'contain',
  },
  container: {
    flex: 1,
  },
  loading: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    margin: '25%',
  },
  btnCoupon: {
    position: 'relative',
    left: '70%',
    top: -40
  },
  button: {
    padding: 10,
    border: 'none',
    color: 'white',
    borderRadius: '5px',
    fontWeight: 'lighter',
    backgroundColor: 'black',
    fontSize: 'large'
  },
});

export default function Coupon({ burn, keyCupon, cupon, history }) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [image, setImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);
  const { token } = useSelector((state) => state.loginReducer.userLoggedIn);

  useEffect(() => {
    setImage(cupon?.img_brief || single_coupon);
  }, [cupon]);

  useEffect(() => {
    setLoadingImage(false);
  }, [image]);


  const selectCoupon = () => {
    dispatch(setCouponSelected(cupon))
    history.push(token ? '/app/cupon' : '/consumidores/cupon')
  }


  return (
    <div key={keyCupon}>
      {
        !cupon.created_at?.length ?
          (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )
          :
          (

            <Card className={classes.root}>
              <CardHeader
                title=""
                style={{ padding: 0 }}
              />
              <CardMedia
                className={classes.media}
                image={image}
                title="Cupones"
              />
              <div className={classes.btnCoupon}>
                {
                  !burn ?
                    <button className={classes.button}
                      onClick={selectCoupon}>
                      CANJEAR
                    </button> :
                    <button className={classes.button}>
                      Canjeado el:
                      <br />
                      {cupon?.created_at.slice(0, cupon?.created_at.indexOf('T'))}
                    </button>
                }
              </div>
            </Card>

          )}
    </div>

  );
}

import { makeStyles } from '@material-ui/core';
import colors, { paleta } from '../assets/colors';
const facebookCol = '#3976ab';
const googleCol = '#c95b51'

export const useStyles = makeStyles(() => ({

    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.palette.primary,
        height: '96vh',
        maxWidth: 750,
    },

    inputsContainersText: {
        flex: 3,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },

    btnRegister: {
        flex: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },

    formContent: {
        flex: 1,
        display: 'flex',
        height: '60vh',
        flexDirection: 'column',
        padding: '0 0 2vh 0',
        alignItems: 'center',
    },

    input: {       
        flex: 1,
        width: '75vw',
        padding: '18px 14px', 
        paddingLeft: 45,
        marginLeft: 15,
        borderRadius: '10px',      
        border: 'none',
        outline: 'none',
        '&::placeholder': {
            color: colors.palette.secondary,
        }
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: '14px 0px',
        maxWidth: 450,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%'
    },
    icon: {
        width: 70,
        padding: 6,
        position: 'absolute',
        left: '-15px'
    },
    socialMediaIcon: {
        width: 55,
        padding: 5,

    },
    button: {
        cursor: 'pointer',
        borderRadius: '10px',
        backgroundColor: ({ isFetching }) => isFetching ? '#c1c1c1' : '#231f20ff',
        color: '#ffffffff',
        border: 'none',
        padding: 14,
        width: '80vw',
        alignSelf: 'center',
        outline: 'none',
    },
    errorText: {
        color: paleta.blanco,
        margin: 0,
        padding: '0.1%',
    },


    buttonContainer: {
        height: '25vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: '0 0 10vw 10vw',
        backgroundColor: paleta.bordo,
        marginTop: -2,
    },

    buttonGoogle: {
        backgroundColor: googleCol,
        flex: 1,
        width: '55vw',
        borderRadius: '0 10px 10px 0',
        height: 46,
        color: 'white',
        fontSize: 'medium',
        textAlign: 'justify',
        paddingLeft: '18px',
        borderStyle: 'solid',
        borderColor: 'white',
        borderWidth: 1
    },
    buttonFacebook: {        
        backgroundColor: facebookCol,
        flex: 1,
        width: '55vw',
        borderRadius: '0 10px 10px 0',
        height: 46,
        color: 'white',
        fontSize: 'medium',
        textAlign: 'justify',
        paddingLeft: '18px',
        borderStyle: 'solid',
        borderColor: 'white',
        borderWidth: 1,
        display: "flex",
        
        alignItems: "center",
        justifyContent: "flex-start",
    },


    btnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
        maxWidth: 450,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '2%'
    },

    divFatherComponent: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '95vh',
    },
    h1Title: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        color: 'white',
        marginBottom: 0
    },
    loginError: {
        margin: 0,
        fontSize: 16,
        color: 'white',
        textAlign: 'center',
    },
    inputContainerButtons: {
        flex: 1.6,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        width: '100%',
    },
    containerButtons: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        width: '100%',
        marginBottom: '10%'
    }

}));
import React from 'react';
import { makeStyles } from '@material-ui/core';

import colors from '../../../assets/colors';

const useStyles = makeStyles({
  container: {
    backgroundColor: colors.palette.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1
  },
  button: {
    cursor: 'pointer',
    WebkitBorderRadius: '50px',
    borderRadius: '50px',
    backgroundColor: ({ isFetching }) => isFetching ? '#c1c1c1' : '#231f20ff',
    color: '#ffffffff',
    border: 'none',
    padding: '4%',
    width: '50%',
    alignSelf: 'center',
    margin: '3%',
    outline: 'none',
  },
  h1: {
    textAlign: 'center',
    color: '#ffffffff'
  }
});

export default function ({ history }) {
  const classes = useStyles();
  const handleGoHome = () => history.push('/pageprincipal');

  return (
    <div className={classes.container}>
      <h1 className={classes.h1}>Canjeado con exito!</h1>
      <button
        className={classes.button}
        type="submit"
        onClick={handleGoHome}
      >
        Ir a inicio
      </button>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from '../../../hooks/useForm';

import QrReader from 'react-qr-reader';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import Navbar from '../../commons/navBar';
import qr_title_2 from '../../../assets/images/qr_title_2.png';
import { createTransactionQRdata } from '../../../request';
import { useStyles } from './styleQr';
import './index.css'
import { Scanner } from '@material-ui/icons';


function QrScanner({ history, match }) {
  const classes = useStyles();

  const [formValues, handleInputChange, reset] = useForm({});
  const { inputCode } = formValues;

  const { id, title } = useSelector(state => state.couponsReducer.coupon_selected)
  const { token } = useSelector(state => state.loginReducer.userLoggedIn);

  const [scanAble, setScanAble] = useState(true)
  const [qrDetected, setQrDetected] = useState(false)

  useEffect(() => {
    let exec = true;
    if (exec) {
      if (qrDetected?.length > 7) {
        createTransactionQR({ couponId: id, qrCode: qrDetected });
      }
    }
    return () => { exec = false }
  }, [qrDetected]);


  /* ESCANEO */
  // cambiar por data
  const handleScan = async (data) => {
    if (data?.length > 7) {
      setScanAble(false)
      setQrDetected(data)
    } 
  };

  const handleScanInput = () => {
    createTransactionQR({ couponId: id, qrCode: inputCode });
  };
  /* ESCANEO */




  const createTransactionQR = async (data) => {
    await createTransactionQRdata(data, token)
      .then((res) => {

        if (res === 'Transaccion realizada correctamente') {
          history.push('/app/cupon-ok', res)
        } else {
          history.push('/app/cupon-error', res)
        }
      })
      .catch((res) => console.error({ status: true, message: res }));

  }

  const handleError = (err) => console.error(err);


  const QRScaner = () => (
    <div className={classes.qr}>
      <QrReader
        className='qr-image-wrapper'
        delay={500}
        onError={handleError}
        onScan={handleScan}
      />
    </div>
  )


  const inputTextQR = () => (
    <div className={classes.inputQR}>
      <img src={qr_title_2} style={{ width: 290 }} alt="qr-title" className={classes.title} />

      <div className={classes.formContainer}>
        <input
          placeholder="c1O3d4c5"
          className={classes.input}
          name='inputCode'
          value={inputCode}
          onChange={handleInputChange}
        />
        <button
          className={classes.btnOK}
          onClick={handleScanInput}
        >OK
        </button>
      </div>
    </div>
  )

  const bannerQr = () => (
    <div className={classes.camera}>
      <CameraAltOutlinedIcon style={{ fontSize: 40 }} />
      <p className={classes.textCamera}>Scanear código QR en caja</p>
    </div>
  )



  return (
    <div className={classes.containerMain}>
      <Navbar
        link="./home"
        logoRojo={true}
        colorFondo={'none'}
        history={history} />

      {bannerQr()}

      <div className={classes.divFather}>

        <div className={classes.containerBackground}>
          {scanAble && QRScaner()}
        </div>

        {inputTextQR()}
      
        <p>{scanAble}</p>
      </div>

    </div >
  );
}



export default QrScanner;

import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

/**Reducers */
import couponsReducer from './redux/reducers/couponsReducer';
import registerReducer from './redux/reducers/registerReducer';
import landingReducer from './redux/reducers/landingReducer';
import canjeReducer from './redux/reducers/canjeReducer';
import loginReducer from './redux/reducers/loginReducer';
import pointOfSaleReducer from './redux/reducers/pointOfSaleReducer';


const reducers = combineReducers({
  loginReducer,
  couponsReducer,
  registerReducer,  
  canjeReducer,
  landingReducer,
  pointOfSaleReducer
});


const persistConfig = {
  key: 'reducer',
  storage: storage,
};

const presistedReducer = persistReducer(persistConfig, reducers);


export const store = createStore(
  presistedReducer,
  composeWithDevTools(
    applyMiddleware(thunk)
  ));

export const persistor = persistStore(store);



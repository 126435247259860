import React from 'react';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Navbar from '../../commons/navBar';
import { useStyles } from './styleQr';
import { Button } from '@material-ui/core';
import './index.css'

export const QrError = (props) => {
    const classes = useStyles();
    const { res, history } = props;

    return (
        <div className={classes.containerMain}>
            <Navbar
                link="./home"
                logoRojo={true}
                colorFondo={'none'}
                history={history} />

            <div className={classes.okScreen}>
                <div>
                    <ErrorOutlineOutlinedIcon style={{ fontSize: '20vh', color: '#d78888' }} />
                </div>

                <div style={{ textAlign: 'center', marginBottom: '10%' }}>
                    <h2>Oh oh!</h2>
                    <p>{res || 'Cupon caducado o no disponible para este punto de venta.'}</p>
                </div>
                <Button variant="contained"
                    className={classes.button}
                    onClick={() => history.push('/app/mis-cupones-disponibles')}>Ir a cupones disponibles</Button>
            </div>
            <p>{res}</p>
        </div>
    )
}
import { typesApp } from '../types';


export const actLogin = (user) => {
  return (dispatch) => { dispatch(login(user)) }
}

const login = (user) => ({
  type: typesApp.lOGIN_USER,
  payload: { user }
})



export const actLogout = () => {
  return (dispatch) => { dispatch(logout()) }
}

const logout = () => ({
  type: typesApp.LOGOUT,
  payload: {}
})



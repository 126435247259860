import React from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";

import {
  makeStyles,
  SwipeableDrawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  Box,
  ListItemText,
} from "@material-ui/core";

import { Menu } from "@material-ui/icons";
import userPhoto from "../../../assets/icons/favorito.png";

import homeIcon from "../../../assets/icons/drawer/icon-01.jpg";
import homeText from "../../../assets/icons/drawer/txt-01.jpg";

import centrosIcon from "../../../assets/icons/drawer/icon-02.png";
import centroText from "../../../assets/icons/drawer/txt-02.jpg";

import couponsIcon from "../../../assets/icons/drawer/icon-03.png";
import couponText from "../../../assets/icons/drawer/txt-03.png";

import favIcon from "../../../assets/icons/drawer/icon-04.jpg";
import favText from "../../../assets/icons/drawer/txt-04.jpg";

import loginIcon from "../../../assets/icons/drawer/icon-05.jpg";
import loginText from "../../../assets/icons/drawer/txt-06.jpg";

import logoutIcon from "../../../assets/icons/drawer/icon-06.jpg";
import logoutText from "../../../assets/icons/drawer/txt-05.jpg";

import { actLogout } from "../../../redux/actions/loginActions";

import styles from "../../../assets/colors";

const useStyles = makeStyles({
  list: {
    flex: 1,
    width: 280,
    paddingTop: 10,
    backgroundColor: "#231f20",
  },
  fullList: {
    width: "auto",
    backgroundColor: "#231f20",
  },
  button: {
    padding: 15,
    margin: "6px 0px",
    alignItems: "center",
  },
  iconBtn: {
    paddingLeft: 12,
  },
  text: {
    color: styles.palette.white,
  },
  title: {
    fontSize: "large",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    color: "white",
  },
});

export default function Drawer({ history, colorCustom }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    uid,
    social_network,
    first_name,
    last_name,
    profile_image,
    email,
    token,
  } = useSelector((state) => state.loginReducer.userLoggedIn);

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  function ImageIcon({ dir }) {
    return (
      <div>
        <img src={dir} alt="icon" style={{ width: 40 }} />
      </div>
    );
  }

  function TextIcon({ dir, promo = false }) {
    return (
      <div>
        {promo ? (
          <img
            src={dir}
            alt="icon"
            style={{
              width: 76,
              marginLeft: 12,
            }}
          />
        ) : (
          <img
            src={dir}
            alt="icon"
            style={{
              width: 100,
              marginLeft: 12,
            }}
          />
        )}
      </div>
    );
  }

  const items = [
    {
      tag: "home",
      name: <TextIcon dir={homeText} />,
      onClick: () => history.push("/consumidores/home"),
      icon: <ImageIcon dir={homeIcon} />,
      show: true,
    },
    {
      tag: "cupones",
      name: <TextIcon dir={couponText} promo={true} />,
      onClick: () => {
        if (token) {
          history.push("/app/mis-cupones-disponibles");
        } else {
          history.push("/consumidores/cupones");
        }
      },
      icon: <ImageIcon dir={couponsIcon} />,
      show: true,
    },
    {
      tag: "mis-cupones",
      name: <TextIcon dir={favText} />,
      onClick: () => history.push("/app/mis-cupones-canjeados"),
      icon: <ImageIcon dir={favIcon} />,
      show: token ? true : false,
    },
    {
      tag: "puntos-venta",
      name: <TextIcon dir={centroText} />,
      onClick: () => history.push("/consumidores/puntos-venta"),
      icon: <ImageIcon dir={centrosIcon} />,
      show: true,
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    dispatch(actLogout());
    history.push("/consumidores");
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {token && (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <div>
            <h5 className={classes.title}>
              Usuario:
              <br />
              {first_name} {last_name}
            </h5>
            <hr style={{ color: "white" }} />
          </div>
          <div>
            <img
              style={{
                maxWidth: "75px",
                borderRadius: "50%",
              }}
              src={profile_image || userPhoto}
            />
          </div>
        </div>
      )}

      <List>
        {items.map(
          (elemento) =>
            elemento.show && (
              <ListItem
                button
                key={elemento.tag}
                onClick={(el) => elemento.onClick(el, anchor)}
                className={classes.button}
              >
                <ListItemIcon className={classes.iconBtn}>
                  {elemento.icon}
                </ListItemIcon>
                <ListItemIcon className={classes.icon}>
                  {elemento.name}
                </ListItemIcon>
              </ListItem>
            )
        )}
      </List>

      <Divider />

      <List>
        {token?.length ? (
          <ListItem button onClick={handleLogout}>
            <ListItemIcon className={classes.iconBtn}>
              <ImageIcon dir={logoutIcon} />
            </ListItemIcon>
            <ListItemIcon className={classes.icon}>
              <TextIcon dir={logoutText} />
            </ListItemIcon>
          </ListItem>
        ) : (
          <ListItem button onClick={() => history.push("/account/login")}>
            <ListItemIcon className={classes.iconBtn}>
              <ImageIcon dir={loginIcon} />
            </ListItemIcon>
            <ListItemIcon className={classes.icon}>
              <TextIcon dir={loginText} />
            </ListItemIcon>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <Menu style={{ color: colorCustom }} />
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

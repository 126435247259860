import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import firebase from "firebase";

import Navbar from "../../commons/navBar";
import { paleta } from "../../../assets/colors";

import icon_mail from "../../../assets/icons/icon-mail.png";
import icon_human from "../../../assets/icons/icon-human.png";
import icon_google from "../../../assets/icons/google.png";
import icon_facebook from "../../../assets/icons/facebook.png";
// import icon_facebook from '../../../assets/icons/facebook-disabled.png';

import { actLogin } from "../../../redux/actions/loginActions";
import { useStyles } from "../../stylesAuth";
import { registerUser } from "../../../request";

/**
 * LOGIN
 */
export default function ({ history }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const loginReducer = useSelector((state) => state.loginReducer);

  const { handleSubmit, register, errors } = useForm();
  const [err, setErr] = useState();

  const providerGoogle = new firebase.auth.GoogleAuthProvider();
  const providerFacebook = new firebase.auth.FacebookAuthProvider();

  // Login solo email
  const onSubmit = ({ userEmail, password }) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(userEmail, password)
      .then((userCredential) => {
        console.log(userCredential);
        const { isNewUser } = userCredential.additionalUserInfo;
        const { xa, uid } = userCredential.user;

        dispatch(
          actLogin({
            uid: uid,
            social_network: "Password",
            first_name: userEmail,
            last_name: "",
            profile_image: "",
            email: userEmail,
            token: xa,
          })
        );

        history.push("/consumidor/home");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setErr(errorCode);
        console.log("Sale por error: ", errorCode, errorMessage);
      });
  };

  // Login Facebook
  const loginWithFacebook = () => {
    firebase
      .auth()
      .signInWithPopup(providerFacebook)
      .then((userCredential) => {
        console.log(userCredential);
        const { id, first_name, last_name, email, picture } =
          userCredential.additionalUserInfo.profile;
        const { isNewUser } = userCredential.additionalUserInfo;
        const { xa } = userCredential.user;
        dispatch(
          actLogin({
            uid: id,
            social_network: "Facebook",
            first_name: first_name,
            last_name: last_name,
            profile_image: picture.data.url,
            email: email ? email : `${first_name}@fbfemsa.com`,
            token: xa,
          })
        );      

        if (isNewUser) {
          registerUser(
            {
              uid: id,
              social_network: "Facebook",
              first_name: first_name,
              last_name: last_name,
              profile_image: picture.data.url,
              email: email ? email : `${first_name}@fbfemsa.com`,
            },
            xa
          );
        }

        history.push("/consumidor/home");
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
      });
  };

  // Login Google
  const loginWithGoogle = () => {
    firebase
      .auth()
      .signInWithPopup(providerGoogle)
      .then((userCredential) => {
        const { id, email, given_name, family_name, verified_email, picture } =
          userCredential.additionalUserInfo.profile;
        const { isNewUser } = userCredential.additionalUserInfo;
        const { xa } = userCredential.user;

        dispatch(
          actLogin({
            uid: id,
            social_network: "Google",
            first_name: given_name,
            last_name: family_name,
            profile_image: picture,
            email: email,
            token: xa,
          })
        );

        if (isNewUser) {
          registerUser(
            {
              uid: id,
              social_network: "Google",
              first_name: given_name,
              last_name: family_name,
              profile_image: picture,
              email: email,
            },
            xa
          );
        }

        history.push("/consumidor/home");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error;
        console.log(errorCode, errorMessage, email, credential);
      });
  };

  const googleBtn = () => (
    <button className={classes.buttonGoogle}>Seguir con Google</button>
  );

  const facebookBtn = () => (
    <div className={classes.buttonFacebook}>Seguir con Facebook</div>
  );

  useEffect(() => {
    let exec = true;
    if (exec) {
      if (loginReducer.length) {
        history.push("/app/misCanjeados");
      }
    }
    return () => {
      exec = false;
    };
  }, [loginReducer]);

  const firebaseGoogleFacebookRegister = () => (
    <div className={classes.buttonContainer}>
      <div onClick={loginWithFacebook} className={classes.btnContainer}>
        <img
          src={icon_facebook}
          alt="logo-facebook"
          className={classes.socialMediaIcon}
        />
        {facebookBtn()}
      </div>

      <div onClick={loginWithGoogle} className={classes.btnContainer}>
        <img
          src={icon_google}
          alt="logo-google"
          className={classes.socialMediaIcon}
        />
        {googleBtn()}
      </div>
    </div>
  );

  const registerForm = () => (
    <form className={classes.formContent} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.inputsContainersText}>
        {err === "auth/user-not-found" ? (
          <p className={classes.loginError}>Usuario o contraseña incorrectos</p>
        ) : null}

        <div className={classes.inputContainer}>
          <img className={classes.icon} src={icon_mail} alt="img-mail" />
          <input
            name="userEmail"
            className={classes.input}
            autoCapitalize="none"
            placeholder="Ingrese su email"
            ref={register({
              required: "Campo obligatorio.",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Correo invalido.",
              },
            })}
          />
        </div>

        {errors.userEmail && errors.userEmail.message && (
          <p className={classes.errorText}>{errors.userEmail.message}</p>
        )}

        <div className={classes.inputContainer}>
          <img className={classes.icon} src={icon_human} alt="img-mail" />
          <input
            name="password"
            type="password"
            autoCapitalize="none"
            placeholder="Ingrese su contraseña"
            className={classes.input}
            ref={register({
              required: "Campo obligatorio.",
            })}
          />
        </div>
        {errors.password && errors.password.message && (
          <p className={classes.errorText}>{errors.password.message}</p>
        )}
      </div>

      <div className={classes.btnRegister}>
        <button className={classes.button} type="submit">
          INGRESAR
        </button>
        <p
          style={{ color: paleta.blanco }}
          onClick={() => history.push("./register")}
        >
          Crear mi cuenta
        </p>
      </div>
      <div style={{ flex: 1 }}></div>
    </form>
  );

  return (
    <div
      style={{
        backgroundColor: paleta.rojoPrincipal,
        height: "100vh",
      }}
    >
      <Navbar logoRojo={false} colorFondo={paleta.bordo} history={history} />

      {firebaseGoogleFacebookRegister()}
      {registerForm()}
    </div>
  );
}

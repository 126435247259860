import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GridCoupons from '../../commons/gridCoupons';
import Navbar from '../../commons/navBar';
import { getCouponsByUser } from '../../../request';

import iconNav from '../../../assets/icons/_ICONO MIS CUPONES.png'
import { CircularProgress } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { useStyles } from '../qrScanner/styleQr';
import { Button } from '@material-ui/core';
import { NavBarTitleCustom } from '../../commons/navBar/NavBarTitleCustom';
import { setCouponSelected } from '../../../redux/actions/couponsActions';
import { useDispatch } from 'react-redux';


const MyCoupons = ({ history }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { loginReducer } = useSelector(state => state);
  const { token } = loginReducer.userLoggedIn;
  const [myCouponsResp, setMyCouponsResp] = useState([])

  const [loading, setLoading] = useState(true)
  const Spinner = () => (
    <div className={classes.containerLoading}><CircularProgress disableShrink /></div>
  )

  useEffect(() => {
    let exec = true;
    if (exec) {
      if (token) { getMyCoupons() }
    }
    return () => { exec = false }
  }, []);


  useEffect(() => {
    let exec = true;
    if (exec) {

      if (myCouponsResp.length === 1) {
        dispatch(setCouponSelected(myCouponsResp[0]))
        history.push('/app/cupon')
      }
      
      setTimeout(() => {
        setLoading(false)        
      }, 1000);

    }
    return () => { exec = false }
  }, [myCouponsResp]);


  const getMyCoupons = async () => {
    await getCouponsByUser(token)
      .then(res => {
        setMyCouponsResp(res)
        setLoading(false)
      })
  }


  const emptyListCoupons = () => (


    <div className={classes.okScreen}>
      <div>
        <ErrorOutlineOutlinedIcon style={{ fontSize: '20vh', color: '#115293' }} />
      </div>
      <div style={{
        textAlign: 'center',
        marginBottom: '10%'
      }}>
        <h2>PROXIMAMENTE<br />NUEVOS CUPONES</h2>
        <p>Estamos preparando las mejores promos para vos.</p>
      </div>
      <Button variant="contained"
        className={classes.button}
        onClick={() => history.push('./home')}>Volver</Button>

    </div>


  )

  return (
    <>

      {
        loading ?
          Spinner() :
          <>
            <Navbar
              link="/inicio"
              logoRojo={true}
              colorFondo={'white'}
              history={history} />

            {myCouponsResp.length ?
              <NavBarTitleCustom titleCustom={"PROMOCIONES"} iconNav={iconNav} /> :
              null}

            {/* {        loading ? 
               <div className={classes.containerLoading}><CircularProgress disableShrink /></div> : */}
            {
              myCouponsResp.length ?
                <GridCoupons
                  titlePrincipal="PROMOCIONES"
                  coupons={myCouponsResp}
                  history={history}
                /> :
                emptyListCoupons()
            }
          </>
      }
    </>
  );
};

export default MyCoupons;

import { typesApp } from '../types';

const INITIAL_STATE = {
  userRegister: {},
};

const registerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {


    default:
      return state;

  }
};

export default registerReducer;


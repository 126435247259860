/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { getCarouselImages } from '../../../redux/actions/landingActions';
import { getSliders } from '../../../request';

const indicatorStyles = {
  background: '#ffffff70',
  width: 13,
  height: 13,
  borderRadius: 20,
  display: 'inline-block',
  margin: '0 15px',
};


const useStyles = makeStyles((theme) => ({
  imgIcons: {
    paddingRight: '20px',
    width: 80
  },
  imgStyles: {
    height: '100%'
  },
 
}));

const Carrousel = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [images, setImages] = useState([])

  // const { images } = useSelector((state) => state.landingReducer);

  useEffect(() => {
    if (!images?.length) { getCarouselImagesRequest() }
  }, []);

  const getCarouselImagesRequest = async () => {
    // await getSliders().then(res => dispatch(getCarouselImages(res)))
    await getSliders().then(res => setImages(res))
  }




  return (
    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Carousel
        
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        autoPlay
        transitionTime={500}
        interval={3000}
        className={classes.carousel}
        infiniteLoop
        showIndicators={true}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{ ...indicatorStyles, background: '#ffffffc4' }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              style={indicatorStyles}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {
          images?.length ?
            images.map(({ id, image, created_at }) => (
              <img key={id} className={classes.imgStyles} src={image} alt="created_at" />
            )) :

            <CircularProgress />
        }
      </Carousel>
    </div>

  );
};

export default Carrousel;

import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Home from '../layout/ui/home';
import Inicio from '../layout/ui/inicio';

import Register from '../layout/ui/register';
import SwapCenters from '../layout/ui/swapCenters';
import AllCoupons from '../layout/ui/allCoupons';
import login from '../layout/ui/login';
import SingleCoupon from '../layout/ui/singleView';


const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginTop: 55,
    display: 'flex',
    backgroundColor: 'black',
  },
  content: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
  },
  toolbar: theme.mixins.toolbar,
}));

const AuthRoutes = function () {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <main className={classes.content}>

        <CssBaseline />
        <Switch>
          <Route exact path="/consumidores" component={Inicio} />
          <Route exact path="/consumidores/home" component={Home} />
          <Route exact path="/consumidores/cupones" component={AllCoupons} />
          <Route exact path="/consumidores/cupon" component={SingleCoupon} />
          <Route exact path="/consumidores/puntos-venta" component={SwapCenters} />
          <Route exact path="/account/login" component={login} />
          <Route exact path="/account/register" component={Register} />

          <Route path="*">
            <Redirect to="/consumidores" />
          </Route>
        </Switch>

      </main>
    </div>
  );
};

export default AuthRoutes;

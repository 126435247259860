import { typesApp } from '../types';


export const getCarouselImages = (arrImages) => {
  return (dispatch) => {
      dispatch(getCarousel(arrImages))
  }
}

const getCarousel = (arrImages) => ({
  type: typesApp.SET_CAROUSEL_IMAGES,
  payload: {
    arrImages
  }
})

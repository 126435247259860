import { typesApp } from '../types';


export const setCouponSelected = (couponSelected) => {
  return (dispatch) => { dispatch(setSelectedCoupon(couponSelected)) }
}

const setSelectedCoupon = (couponSelected) => ({
  type: typesApp.SET_COUPON_SELECTED,
  payload: { couponSelected }
})


import { typesApp } from '../types';

const INITIAL_STATE = {
  userLoggedIn: {},
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case typesApp.lOGIN_USER:
      return {
        ...state,
        userLoggedIn: action.payload.user
      }

    case typesApp.LOGOUT:
      return {
        ...state,
        userLoggedIn: {}
      }

    default:
      return state;

  }
};

export default loginReducer;

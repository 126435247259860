import { typesApp } from "../types";


const INITIAL_STATE = {
	couponId: null,
	qrCode: null
}

const canjeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case typesApp.SET_QR_BURN:
      return {
        ...state,
        qrCode: action.payload.qr
      } 

    default:
      return state;
  }
};

export default canjeReducer;

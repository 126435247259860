import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import axios from 'axios';

import { actLogout } from '../redux/actions/loginActions';
import PrivateRoutes from './privateRoutes';
import AuthRoutes from './authRoutes';


const RoutesContainer = function () {
  const dispatch = useDispatch();
  const { userLoggedIn } = useSelector(state => state.loginReducer);


  useEffect(() => {
    let exec = true;
    if (exec) {
      if (userLoggedIn?.token?.length) {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${userLoggedIn?.token}` };
      } else {
        dispatch(actLogout());
      }
    }
    return () => { exec = false }   
  }, []);



  return (
    <Switch>
      {userLoggedIn?.token ? <PrivateRoutes /> : <AuthRoutes />}
    </Switch>
  );
};

export default RoutesContainer;

import React, { useEffect, useState } from 'react';

import GridCoupons from '../../commons/gridCoupons';
import { getCoupons } from '../../../request';
import Navbar from '../../commons/navBar';
import { useStyles } from '../../stylesCoupons';
import { CircularProgress } from '@material-ui/core';
import iconNav from '../../../assets/icons/_ICONO MIS CUPONES.png'
import { NavBarTitleCustom } from '../../commons/navBar/NavBarTitleCustom';
import { useDispatch } from 'react-redux';
import { setCouponSelected } from '../../../redux/actions/couponsActions';


export default function AllCoupons({ history }) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [allCoupons, setAllCoupons] = useState([])


  const [loading, setLoading] = useState(true)
  const Spinner = () => (
    <div className={classes.containerLoading}><CircularProgress disableShrink /></div>
  )

  useEffect(() => {
    let exec = true;
    if (exec) {
      getAllCurrentCoupons();
    }
    return () => { exec = false }
  }, []);


  useEffect(() => {
    let exec = true;
    if (exec) {

      if (allCoupons?.length === 1) {
        dispatch(setCouponSelected(allCoupons[0]))
        history.push('/consumidores/cupon')
        setLoading(false)
      } 

      setTimeout(() => {
        setLoading(false)
      }, 1000);

    }
    return () => { exec = false }
  }, [allCoupons]);


  const getAllCurrentCoupons = async () => {
    await getCoupons()
      .then(res => setAllCoupons(res))
  }

  return (
    <>
      {
        loading ?
          Spinner() :
          <>
            <div>
              <Navbar
                link={"/consumidores/home"}
                logoRojo={true}
                colorFondo={'white'}
                history={history} />

              {allCoupons.length ?
                <NavBarTitleCustom titleCustom={"PROMOCIONES"} iconNav={iconNav} /> :
                null}
            </div>

            <div className={classes.container}>
              <div className={classes.content}>

                {
                  allCoupons.length ?
                    <GridCoupons
                      titlePrincipal="PROMOCIONES"
                      coupons={allCoupons}
                      history={history}
                    /> :
                    null
                }
              </div>
            </div>
          </>
      }

    </>
  );
}

import axios from 'axios';

const url = 'https://femsa.trazzart.com'


async function getSliders() {
    try {
        const response = await axios.get(`${url}/generic/v1/sliders`);

        if (response.status === 200) {
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}

async function getShops(lat, lng, token) {
    console.log("llega lat lng", lat, lng);
    try {
        const response = await axios.get(
            `${url}/generic/v1/shops/by_geo/${lng}/${lat}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
        if (response.status === 200) {
            console.log(response.data);
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}


async function getShopsByZip(code, token) {
    try {
        const response = await axios.get(
            `${url}/generic/v1/shops/by_zip/${code}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
        if (response.status === 200) {
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}

async function getCoupons() {
    try {
        const response = await axios.get(`${url}/generic/v1/coupons`);

        if (response.status === 200) {
            console.log(response.data);
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}


async function createTransactionQRdata(qrData, token) {
    try {
        const response = await axios.post(
            `${url}/api/v1/coupons/burn`,
            qrData,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


async function getCouponsByUser(token) {
    try {
        const response = await axios.get(
            `${url}/api/v1/coupons/by_user`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
        if (response.status === 200) {
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}


async function getCouponsBurnByUser(token) {
    try {
        const response = await axios.get(
            `${url}/api/v1/coupons/history/by_user`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

        if (response.status === 200) {
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}



// {
//     "uid": "123123123113", 
//     "social_network": "google", 
//     "first_name": "JOSE", 
//     "last_name": "BOZZONE",
//     "profile_image": "LO QUE SEA", 
//     "email": "jose.bozzone@gmail.com"
// }

async function registerUser(user, token) {
    try {
        const response = await axios.post(
            `${url}/api/v1/consumers`,
            user,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


async function createNewShop(shopObj, token) {
    try {
        const response = await axios.post(
            `${url}/api/v1/shops`,
            shopObj,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export {
    getShopsByZip,
    createNewShop,
    registerUser,
    getCouponsByUser,
    getShops,
    getCouponsBurnByUser,
    createTransactionQRdata,
    getSliders,
    getCoupons,
}
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';

import config from '../config/index';

console.log(firebase.apps.length);

  firebase.initializeApp(config.firebaseConfig);


// firebase.auth().signInAnonymously();

export const uploadImage = (base64) => new Promise((resolve, reject) => {
  const storageRef = firebase.storage().ref();
  const file = storageRef.child('testnofunciona.jpg');

  file
    .putString(base64, 'base64')
    .then((snapshot) => resolve(snapshot))
    .catch((error) => reject(error));
});

export const getDownloadUrl = (fileName) => new Promise((resolve, reject) => {
  const storageRef = firebase.storage().ref();
  const file = storageRef.child(fileName);
  file
    .getDownloadURL()
    .then((url) => resolve(url))
    .catch((error) => reject(error));
});




